<template>
  <div
    :class="`${$store.getters['display/getAside'] ? 'contents expanded' : 'contents'}`"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <Breadcrumb :options="breadcrumbOptions" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-30">
          <div class="card">
            <div class="my-card-header color-dark fw-500 pt-3">
              <span class="float-left py-2">
                <span class="h4 px-3">
                  {{ t("filter", {}, { locale: getLocal }) }}
                </span>
              </span>
              <span class="float-right">
                <span class="max-w-px-200 my-dropdown">
                  <label for="startDate">{{ t("startDate", {}, { locale: getLocal }) }}</label>
                  <input id="startDate" v-model="startDate" type="date" />
                </span>
                <span class="max-w-px-200 my-dropdown">
                  <label for="endDate">{{ t("endDate", {}, { locale: getLocal }) }}</label>
                  <input id="endDate" v-model="endDate" type="date" />
                </span>
              </span>
            </div>
            <div class="card-body m-0 p-0">
              <div class="userDatatable global-shadow border-0 bg-white w-100">
                <table class="table styled-table">
                  <thead>
                    <!-- First Header Row -->
                    <tr>
                      <th rowspan="2"> {{ t("report_date", {}, { locale: getLocal }) }} </th>
                      <th colspan="10">Hengsud</th>
                      <th colspan="10">Kiosk</th>
                    </tr>
                    <!-- Second Header Row -->
                    <tr>
                      <th v-for="i in 10" :key="'Hengsud_' + i">ชุดที่ {{ i }}</th>
                      <th v-for="i in 10" :key="'Kiosk_' + i">ชุดที่ {{ i }}</th>
                    </tr>
                  </thead>
                  <tbody id="table_tbody">
                    <tr v-for="(e, index) in listData" :key="index">
                      <td>
                        <div class="userDatatable-content text-center">
                          {{ e.report_date || "-" }}
                        </div>
                      </td>
                      <td v-for="value in e.hengsudValues" :key="'HengsudValue_' + value">
                        <div class="userDatatable-content text-center">
                          {{ value }}
                        </div>
                      </td>
                      <td v-for="value in e.kioskValues" :key="'KioskValue_' + value">
                        <div class="userDatatable-content text-center">
                          {{ value }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  ref="PaginationRef"
                  :Count="count"
                  @PaginationReturnData="PaginationReturnData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as serviceAPI from "../services/API.service";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "DailyReport",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    Pagination,
    Breadcrumb,
  },
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      startDate: today,
      endDate: today,
      breadcrumbOptions: {
        isShowStartSearch: true,
        pageKey: "dailyreport",
        startSearch: this.startSearch,
      },
      listData: [], // Data for the table
      count: 0, // Total count for pagination
      PaginationData: {}, // Pagination parameters
      tableHeaders: [], // Dynamic table headers
    };
  },
  methods: {
    async startSearch() {
      console.log("Start Date:", this.startDate);
      console.log("End Date:", this.endDate);
      this.count = 0;
      this.showData();
    },
    PaginationReturnData(para_val) {
      this.PaginationData = para_val;
      this.showData();
    },
    async showData() {
      try {
        // Define the parameters for multiple requests
        const params = [];
        for (let i = 1; i <= 10; i++) {
          params.push({ set_number: i, client_name: "hengsud" });
          params.push({ set_number: i, client_name: "renny-kiosk-server" });
        }

        // Initialize listData and results for transformation
        this.listData = [];
        const results = [];

        for (const param of params) {
          const data = {
            start: this.PaginationData.offset || 0,
            length: this.PaginationData.limit || 10,
            start_date: this.startDate,
            end_date: this.endDate,
            ...param,
          };

          // Await each API request one by one
          const response = await serviceAPI.call_API(
            "get",
            "lotto/api/v1/getDailyReport",
            data,
            "auth"
          );

          // Collect data and associate it with its parameter
          results.push({
            param,
            data: response.data.data,
          });
        }

        // Transform data for the table display
        this.processData(results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    processData(results) {
    // Combine data for each date
    const combinedData = {};
    results.forEach((result, idx) => {
      result.data.forEach((entry) => {
        if (!combinedData[entry.report_date]) {
          combinedData[entry.report_date] = { report_date: entry.report_date, hengsudValues: [], kioskValues: [] };
        }

        // Populate the correct index for Hengsud and Kiosk
        const clientIndexOffset = result.param.client_name === "hengsud" ? 0 : 10;
        combinedData[entry.report_date][
          result.param.client_name === "hengsud" ? "hengsudValues" : "kioskValues"
        ][result.param.set_number - 1] = this.convertToInt(entry.sum_lotto);
      });
    });

    // Format data for the table display
    this.listData = Object.values(combinedData)
      .map((row) => {
        // Fill missing values with 0
        row.hengsudValues = Array.from({ length: 10 }, (_, i) => row.hengsudValues[i] || 0);
        row.kioskValues = Array.from({ length: 10 }, (_, i) => row.kioskValues[i] || 0);
        return row;
      })
      .sort((a, b) => {
        // Sort by report_date in ascending order
        if (a.report_date === "-" || b.report_date === "-") return 0; // Ignore invalid dates
        return new Date(a.report_date) - new Date(b.report_date);
      });
  },
  convertToInt(value) {
    // Ensure the value is a number, defaulting to 0 if invalid
    if (value === null || value === undefined || value === "") {
      return 0;
    }
    const intValue = parseInt(value, 10);
    return isNaN(intValue) ? 0 : intValue; // Return 0 if not a valid number
  },
  },
  computed: {
    getLocal() {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style scoped>
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table th,
.styled-table td {
  text-align: center;
  padding: 4px;
  border: 1px solid #ddd;
  font-size: 12px;
  white-space: nowrap; /* Prevent text wrapping */
}

.styled-table thead th {
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
}

.styled-table thead th[colspan] {
  background-color: #0056b3; /* Slightly darker for grouped columns */
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
  background-color: #e6f7ff;
}

.userDatatable-content {
  font-size: 13px;
  padding: 5px;
}

.userDatatable .max-w-px-200 input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
</style>